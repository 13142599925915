.spinner-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(52, 58, 64);
}
.spinner {
  margin: 0 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 4px solid #7e7c7c;
  border-top: 4px solid white;
  animation: rotating-spinner 1s linear infinite;
}
@keyframes rotating-spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
/* Web */
.spinner-container-web {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* Submit */
.spinner-container-submit {
  position: fixed;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(154, 160, 167, 0.3);
  top: 0;
  z-index: 1;
}
